import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Home",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },

    {
      path: "/checkout",
      name: "checkout",
      component: () => import("@/views/checkout pages/Checkout.vue"),
      meta: {
        pageTitle: "Home",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/account-setting",
      name: "account-setting",
      component: () => import("@/views/account-setting/AccountSetting.vue"),
      meta: {
        pageTitle: "Account Settings",
        breadcrumb: [
          {
            text: "Pages",
          },
          {
            text: "Account Settings",
            active: true,
          },
        ],
      },
    },

    {
      path: "/list-courses",
      name: "list-courses",
      component: () => import("@/views/courses pages/list courses.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "courses",
        breadcrumb: [
          {
            text: "list-courses",
            active: true,
          },
        ],
      },
    },
    {
      path: "/courses/:id",
      name: "preview-course",
      component: () => import("@/views/courses pages/coursePreview.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "coursePreview ",
        breadcrumb: [
          {
            text: "coursePreview",
            active: true,
          },
        ],
      },
    },

    {
      path: "/courses-progress/:id",
      name: "courses-progress",
      component: () => import("@/views/courses pages/courseprogress.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "courses-progress ",
        breadcrumb: [
          {
            text: "courses-progress",
            active: true,
          },
        ],
      },
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/auth pages/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/auth-forgot-password",
      name: "auth-forgot-password",
      component: () => import("@/views/auth pages/ForgotPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/auth-reset-password-code",
      name: "auth-reset-password-code",
      component: () => import("@/views/auth pages/ResetPasswordCode.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/auth-reset-password",
      name: "auth-reset-password",
      component: () => import("@/views/auth pages/ResetPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/auth pages/Register.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
