import axios from "@axios";

export default {
  namespaced: true,
  state: {
    cartItems: [],
    cartItemsCount: 0,
  },
  getters: {},
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItems.push(course);

      state.cartItemsCount = count;
    },
    addCourseInCart(state, course) {
      if (!state.cartItems.find((e) => e._id === course._id)) {
        state.cartItems.push(course);
        state.cartItemsCount++;
      }
    },
    deleteCoursefromCart(state, course) {
      state.cartItems.splice(
        state.cartItems.findIndex((obj) => obj.id === course.id),
        1
      );
      state.cartItemsCount--;
    },
  },
  actions: {
    fetchCartProducts() {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get("/apps/ecommerce/cart")
      //     .then((response) => resolve(response))
      //     .catch((error) => reject(error));
      // });
    },
    addCourseInCart({ commit, dispatch }, course) {
      console.log(course + "hiiiiiiii");
      commit("addCourseInCart", course);
      // return new Promise((resolve, reject) => {
      //   axios
      //     .post("/apps/ecommerce/cart", { productId })
      //     .then((response) => resolve(response))
      //     .catch((error) => reject(error));
      // });
    },
    removeCourseFromCart({ commit, dispatch }, course) {
      console.log(course + "hiiiiiiii");
      commit("deleteCoursefromCart", course);
    },
  },
};
